<template>
  <div>
    <div class="tabs">
      <div class="container">
        <p @click="tabActive = 1" :class="{active: tabActive === 1}">粉尘防爆及其他安全设施改造</p>
        <p @click="tabActive = 2" :class="{active: tabActive === 2}">易燃易爆化学品防火柜</p>
        <p @click="tabActive = 3" :class="{active: tabActive === 3}">一般化学品防火柜</p>
        <p @click="tabActive = 4" :class="{active: tabActive === 4}">危险废弃物暂存柜</p>
        <p @click="tabActive = 5" :class="{active: tabActive === 5}">化学品恒温柜</p>
        <p @click="tabActive = 6" :class="{active: tabActive === 6}">气瓶柜</p>
      </div>
    </div>
    <div class="content">
      <div class="container" v-if="tabActive === 1">
        <h5 class="fs-24">内容概述</h5>
        <div class="summary">
          <div class="text">
            <p>针对近年来爆炸事故频发，我公司提供粉尘防爆及其他安全设施改造工程，通过现场咨询评估、安全设计、产品销售、现场改造安装以及检测验收一站式服务。</p>
            <p>改造工程依据AQ4273、GB15577要求，提供高于标准的泄爆装置、隔爆阀、灭火系统、自动控制系统等安全设施，将金属粉尘、食品粉尘、化工粉尘、木制品粉尘等涉爆粉尘及其他相关危险、有害因素控制在安全范围内，提高本质安全性，为企业彻底解决安全隐患，牢筑安全生产防线。</p>
          </div>
        </div>
        <div class="tabContent">
          <div>
            <p>除尘器设备组成： </p>
            <span>灰斗、过滤室、净气室、支架、提升阀、喷吹清灰装置 </span>
            <p>除尘器工作原理：  </p>
            <span>工作时,含尘气体由风道进入灰斗。大颗粒的粉尘直接落入灰斗底部,较小的粉尘随气流转折向上进入过滤室,并被阻留在滤袋外表面,净化了的烟气进入袋内,并经袋口和净气室进入出风道,由排风口排出 </span>
            <p>可用于粉尘类型：  </p>
            <span>静电喷涂粉尘、树脂粉尘、铝镁等金属粉尘、食品粉尘等不同类型粉尘的收集处理需要</span>
          </div>
          <img src="@/assets/img/gcxm01.png" alt="">
        </div>
      </div>
      <div class="container" v-if="tabActive === 2">
        <h5 class="fs-24">内容概述</h5>
        <div class="summary">
          <div class="text">
            <p>本产品通过了国内防火防爆认证，拥有欧盟防爆认证（ATEX）合格证书，柜体耐火极限经专业检测机构检测，耐火极限达到3.0h。</p>
            <p>柜体顶部泄爆，周围结构抗爆，配备公安部消防认证甲级防火门，箱体整体焊接钢骨结构框架，柜体内外皆有涂层，防火且耐腐蚀，设备主体十年报修。</p>
          </div>
        </div>
        <div class="tabContent">
          <div>
            <p>适用于： </p>
            <span>工贸等企业储存、使用易燃易爆物质，具有爆炸气体或蒸汽环境；海拔高度不超过 2000 米；25℃时，相对湿度 95％以下；工作环境温度-20℃～+40℃之间的环境</span>
            <p>柜体种类：  </p>
            <span><span style="color:#000;font-weight: bolder;margin: 0 10px;">步入式:</span>步入式适宜存放小容积或者零散化学品，方便人员进出拿取 </span><br>
            <span><span style="color:#000;font-weight: bolder;margin: 0 10px;">层架式:</span>层架式适宜存放大容积或标准货物，叉车可在柜体外直接叉取物品，取用方便 </span><br>
            <span><span style="color:#000;font-weight: bolder;margin: 0 10px;">可定制:</span>柜体尺寸除常规尺寸外，也可定制。为了适应企业的不同存储需求，我司提供有防爆控制系统、自动探测系统、自动灭火装置、防渗漏系统、温湿度控制系统、通风系统、静电消除设施、照明系统等供企业选择，并委派专业技术人员为企业提供技术指导。 </span>
          </div>
          <img src="@/assets/img/gcxm02.png" alt="">
        </div>
      </div>
      <div class="container" v-if="tabActive === 3">
        <h5 class="fs-24">内容概述</h5>
        <div class="summary">
          <div class="text">
            <p>柜体设置防火通风口，即可防止外部火焰延烧至内部，又能在内部存在火焰时可关闭阻隔氧气。</p>
            <p>柜体设置特殊保温层，具有隔音、保温、阻热、密封、耐热、耐寒、易清洁、易维护等特点，且设备主体十年报修。</p>
          </div>
        </div>
        <div class="tabContent">
          <div>
            <p>柜体种类：  </p>
            <span><span style="color:#000;font-weight: bolder;margin: 0 10px;">步入式:</span>步入式带钢制货架，方便存取</span><br>
            <span><span style="color:#000;font-weight: bolder;margin: 0 10px;">层架式:</span>层架式带插槽，方便叉车取用</span>
            <p>产品优点： </p>
            <span>产品结构紧凑，操作方便，适用范围广，使用安全可靠，符合法律法规要求，尺寸型号颜色均可根据客户需求定制，我们将委派专业技术人员为企业提供技术指导，真正做到安全存储。</span>
          </div>
          <img src="@/assets/img/gcxm03.png" alt="">
        </div>
      </div>
      <div class="container" v-if="tabActive === 4">
        <h5 class="fs-24">内容概述</h5>
        <div class="summary">
          <div class="text">
            <p>危险废弃物暂存柜用于暂存不同类型的有毒有害废弃物，为易燃及危险化学品提供良好的储存环境，拥有防爆防渗漏等特点，可有效的防护人们生命财产的安全。</p>
          </div>
        </div>
        <div class="tabContent">
          <div>
            <p>产品优点： </p>
            <span>柜体框架强度高，多层油漆喷涂，拥有防火防爆认证，配备的防漏池为耐腐蚀型，容量可满足泄露容积比，防积水，底部安装了高为300毫米的支座，自带无人值守的设备及系统，信号输出稳定，抗干扰 </span>
            <p>产品可选配：  </p>
            <span>自动控制系统、内部存储系统、监控系统、通风排气温控系统、防静电避雷系统、防爆系统、个人防护系统、废气净化系统，以满足不同客户的需求</span>
          </div>
          <img src="@/assets/img/gcxm04.png" alt="">
        </div>
      </div>
      <div class="container" v-if="tabActive === 5">
        <h5 class="fs-24">内容概述</h5>
        <div class="summary">
          <div class="text">
            <p>化学品恒温保存柜适用于恒温存储特定化学品，柜体整体焊接钢骨结构，内外壁经先进防腐化喷涂工艺涂层，防火、耐腐蚀、控温精度高，可持久使用。</p>
          </div>
        </div>
        <div class="tabContent">
          <div>
            <p>产品优点：</p>
            <span>有高低温报警、温感器故障报警和安全锁功能，防止出现意外，精准温感探头，便于随时观察箱体内温度变化，采用强制空气循环，确保箱体恒温无死角。降温/制热速度快，设定的温度在短时间里，即可达到设置温度要求。</span>
            <br>
            <span style="margin-top: 40px;display: block">柜体整体拥有防火、防爆认证，柜体结构耐火等级三小时以上，配备获得公安部消防认证甲级防火门，可提供10年保修售后服务</span>
          </div>
          <img src="@/assets/img/gcxm05.png" alt="">
        </div>
      </div>
      <div class="container" v-if="tabActive === 6">
        <h5 class="fs-24">内容概述</h5>
        <div class="summary">
          <div class="text">
            <p>气瓶柜用于工作区域的气瓶防火储存，是保护钢瓶（气瓶）不受柜子外面火灾以及保护周围物免受柜体内部火灾的金属容器。</p>
            <p>全钢结构，柜体采用优质冷轧板，表面均需经过严格的酸洗、磷化处理后，再进行环氧树脂静电喷涂耐腐蚀处理，耐潮耐热，气瓶柜两侧有PASS孔，保证柜体内气体流动。</p>
          </div>
        </div>
        <div class="tabContent">
          <div>
            <p>产品可配备：</p>
            <span>微电脑定时开关，高敏探头检测漏气自动排风，实现气体泄漏自动报警、时间显示、自动定时排风等多种功能，从而防止气体泄漏、燃爆等现象的发生</span>
          </div>
          <img src="@/assets/img/gcxm06.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GCXM',
  components: {},
  props: {},
  data () {
    return {
      tabActive: 1
    }
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    if (this.$route.query.tabActive) {
      this.tabActive = Number(this.$route.query.tabActive)
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
  .tabs {
    width: 100%;
    height: 70px;
    background-color: #F2F2F2;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        cursor: pointer;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .active {
        background-color: #42C348;
        color: white;
      }
    }
  }
  .content {
    padding: 60px 0;
    .summary {
      margin-top: 30px;
      background: url("~@/assets/img/gcxm-content-bg.png");
      background-size: cover;
      height: 230px;
      padding: 30px 40px;
      .text {
        color: #FFFFFF;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        flex-direction: column;
        p {
          margin-left: 30px;
          line-height: 1.8;
        }
      }
    }
    .tabContent {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 260px;
        margin-left: 80px;
        margin-right: 10px;
      }
      > div {
        p {
          color: #3CB133;
          margin: 20px 0;
          text-indent: 16px;
          position: relative;
          &:before {
            content: ' ';
            position: absolute;
            left: 0;
            top: 6px;
            width: 8px;
            height: 8px;
            background-color: #3CB133;
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>
